import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useTranslation } from 'i18n/';
import { Followed } from 'models/Followed';
import { User } from 'models/User';
import { UserController } from 'services/apiClient/interfaces/UserController';
import { useNotify } from 'services/notifications';

import { useAuth } from './AuthProvider';

const defaultConfig = {
  hasMusic: false,
  hasPaymentMethods: false,
};

function optimisticUpdate(queryKey, queryCache, predictiveUpdate) {
  queryCache.cancelQueries(queryKey);
  const prevData = queryCache.getQueryData(queryKey);
  queryCache.setQueryData(queryKey, () => predictiveUpdate(prevData));
  return () => queryCache.setQueryData(queryKey, prevData);
}

export function useUser({ hasMusic = false, hasPaymentMethods = false } = defaultConfig) {
  const { t, lang } = useTranslation();
  const notify = useNotify();
  const { isAuthenticated, removeAuthAccessToken, accessToken, refreshToken } = useAuth();
  const queryClient = useQueryClient();

  const onSuccess = useCallback(() => notify.success(t('userPage:profileSaved')), [lang]);

  const userQuery = 'user';
  const { data: userData, isFetching: isFetchingMe } = useQuery(userQuery, UserController.getMe, {
    enabled: isAuthenticated && !!accessToken,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!userData && !isFetchingMe && accessToken !== '') removeAuthAccessToken();
  }, [userData, isFetchingMe]);
  const refetchUser = useCallback(() => queryClient.invalidateQueries(userQuery), []);

  const followedQuery = 'user-followed';
  const { data: saved, isFetching: isFetchingSaved } = useQuery(
    followedQuery,
    UserController.getFollowed,
    {
      enabled: isAuthenticated && !!userData?.id && !!refreshToken,
      staleTime: Infinity,
    }
  );

  const musicQuery = ['user-music', { id: userData?.id }];
  const { data: musicGenres, isFetching: isFetchingMusic } = useQuery(
    musicQuery,
    () => UserController.getMusic({ id: userData?.id }),
    {
      enabled: hasMusic && !!userData?.id,
    }
  );

  const paymentsQuery = ['user-payment-methods', { id: userData?.paymentAccounts.adyen?.userId }];
  const { data: paymentMethods, isFetching: isFetchingPayments } = useQuery(
    paymentsQuery,
    () => UserController.getPaymentMethods({ id: userData?.paymentAccounts.adyen?.userId }),
    {
      enabled: hasPaymentMethods && !!userData?.paymentAccounts.adyen.userId,
    }
  );
  const refetchPayments = () => queryClient.invalidateQueries(paymentsQuery);

  const reviewsQuery = 'user-reviews';
  const { data: reviews, isFetching: isFetchingReviews } = useQuery(
    reviewsQuery,
    UserController.getReviews,
    { enabled: isAuthenticated && !!userData?.id }
  );
  const refetchReviews = () => queryClient.invalidateQueries(reviewsQuery);

  const { mutateAsync: updateProfile, isLoading: isLoadingUpdateMe } = useMutation(
    UserController.updateMe,
    {
      onMutate: updates => {
        return optimisticUpdate(
          userQuery,
          queryClient,
          prevUser => new User({ ...prevUser, ...updates })
        );
      },
      onSuccess,
      onSettled: refetchUser,
    }
  );

  const { mutateAsync: updateFollowed, isLoading: isLoadingUpdateFollowed } = useMutation(
    UserController.updateFollowed,
    {
      onMutate: ({ type, id, action }) => {
        return optimisticUpdate(followedQuery, queryClient, prevFollowed => {
          const newSet = new Set(prevFollowed[type]);

          if (action === 'save') newSet.add(id);
          else if (action === 'unsave') newSet.delete(id);

          return new Followed({ ...prevFollowed, [type]: newSet });
        });
      },
      onError: error => notify.error(error.message),
      onSettled: (_data, _error, { type }) => {
        queryClient.invalidateQueries([`saved-${type}`]);
        queryClient.invalidateQueries([type.slice(0, -1)]);
      },
    }
  );

  const { mutateAsync: updateMusic, isLoading: isLoadingUpdateMusic } = useMutation(
    UserController.updateMusic,
    {
      onMutate: ({ musicGenres: updates }) => {
        return optimisticUpdate(musicQuery, queryClient, () => updates);
      },
      onError: error => notify.error(error.message),
      onSuccess,
      onSettled: () => queryClient.invalidateQueries(musicQuery),
    }
  );

  const { mutateAsync: updateImage, isLoading: isLoadingUpdateImage } = useMutation(
    UserController.updateImage,
    {
      onMutate: file => {
        if (!file) return null;
        return optimisticUpdate(
          userQuery,
          queryClient,
          prev => new User({ ...prev, profileImageUrl: null })
        );
      },
      onError: error => notify.error(error.message),
      onSuccess,
      onSettled: refetchUser,
    }
  );

  const { mutateAsync: updateEmail, isLoading: isLoadingUpdateEmail } = useMutation(
    UserController.updateEmail,
    {
      onSettled: refetchUser,
    }
  );

  const { mutateAsync: updatePassword, isLoading: isLoadingUpdatePassword } = useMutation(
    UserController.updatePassword,
    {
      onError: error => notify.error(error.message),
      onSettled: refetchUser,
    }
  );

  const { mutateAsync: updateReview, isLoading: isLoadingUpdateReview } = useMutation(
    UserController.updateReview,
    {
      onError: err => notify.error(err.message),
      onSettled: refetchReviews,
    }
  );

  const { mutateAsync: deletePaymentMethod, isLoading: isLoadingDeletePayment } = useMutation(
    UserController.deletePaymentMethod,
    {
      onMutate: ({ methodId }) => {
        return optimisticUpdate(paymentsQuery, queryClient, prevMethods =>
          prevMethods.filter(item => item.creditCardId !== methodId)
        );
      },
      onError: error => notify.error(error.message),
      onSuccess: () => notify.success(t('userPage:paymentMethodDeletedSuccessMsg')),
      onSettled: refetchPayments,
    }
  );

  const { mutateAsync: createReview, isLoading: isLoadingCreateReview } = useMutation(
    UserController.createReview,
    {
      onError: err => notify.error(err.message),
      onSettled: refetchReviews,
    }
  );

  const { mutateAsync: deleteReview, isLoading: isLoadingDeleteReview } = useMutation(
    UserController.deleteReview,
    {
      onError: err => notify.error(err.message),
      onSettled: refetchReviews,
    }
  );

  // Remove from cache the user data if is not authenticated. Prevents browser back navigation to display user's information
  useEffect(() => {
    if (!isAuthenticated) {
      queryClient.removeQueries(userQuery);
    }
  }, [isAuthenticated]);

  const user = useMemo(
    () =>
      userData &&
      new User({
        ...userData,
        ...(saved && { saved }),
        ...(musicGenres && { musicGenres }),
        ...(paymentMethods && { paymentMethods }),
        ...(reviews && { reviews }),
      }),
    [userData, saved, musicGenres, paymentMethods, reviews]
  );

  const isLoading =
    isFetchingMe ||
    isFetchingSaved ||
    isFetchingMusic ||
    isFetchingPayments ||
    isFetchingReviews ||
    isLoadingCreateReview ||
    isLoadingUpdateMe ||
    isLoadingUpdateFollowed ||
    isLoadingUpdateMusic ||
    isLoadingUpdateImage ||
    isLoadingUpdateEmail ||
    isLoadingUpdatePassword ||
    isLoadingUpdateReview ||
    isLoadingDeletePayment ||
    isLoadingDeleteReview;

  return {
    user,
    isLoading,
    createReview,
    updateProfile,
    updateFollowed,
    updateMusic,
    updateImage,
    updateEmail,
    updatePassword,
    updateReview,
    deletePaymentMethod,
    deleteReview,
    refetchUser,
    refetchPayments,
  };
}
