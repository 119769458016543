import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { background, color } from 'styled-system';

import {
  Flex,
  FullWidthContainer,
  Loader,
  SecondaryText,
  TopBar,
  useBoolean,
  useMediaQuery,
  UserCard,
} from '@xceedsrl/jukebox';

import { useTranslation } from 'i18n/';
import { useAuth } from 'services/authClient';
import { useUser } from 'services/authClient/useUser';
import GoogleTagManager from 'services/GoogleTagManager';
import { useSharedDataActions, useSharedDataState } from 'store/sharedData';

import Link from 'components/CustomLink';

const NavBarContainer = styled.nav`
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  z-index: 10;
  ${color}
  ${background}
`;

const Navbar = ({ theme, setRef, ...props }) => {
  const { shouldShowCities, shouldShowSearch } = useSharedDataState();
  const { openAuth, openCities, openSearch, setNavbarBoundingClientRect } = useSharedDataActions();
  const [host, setHost] = useState('xceed.me');
  const { isAuthenticated, signout, isLoading } = useAuth();
  const { user } = useUser();
  const { t, lang } = useTranslation();
  const router = useRouter();
  const { value, on, off } = useBoolean(false);

  const navbarRef = useRef();

  useEffect(() => {
    if (setRef) setNavbarBoundingClientRect(navbarRef.current.getBoundingClientRect());
  }, [navbarRef.current]);

  const isMobileOrTablet = useMediaQuery('mobile', 'tablet');

  const toggleAuth = useCallback(() => {
    GoogleTagManager.loginTopbar();
    openAuth();
  }, [openAuth]);

  const toggleCities = useCallback(() => {
    GoogleTagManager.discoverTopbar();
    openCities();
  }, [openCities]);
  const toggleSearch = useCallback(() => {
    GoogleTagManager.searchTopbar();
    openSearch();
  }, [openSearch]);

  useEffect(() => {
    if (window.location.hostname !== host) setHost(window.location.hostname);
  }, []);

  const content = useMemo(
    () => ({
      login: t('common:login'),
      logout: t('common:logout'),
      saved: t('common:saved'),
      discover: t('common:discover'),
      bookings: t('common:bookings'),
      editProfile: t('common:editProfile'),
      listAnEvent: t('common:listAnEvent'),
      searchPlaceholder: t('common:searchPlaceholder'),
    }),
    [lang]
  );

  const User = (
    <UserCard>
      {isAuthenticated && (
        <>
          <Link href="/user/profile" as="/user/profile" aria-label="Profile">
            <UserCard.User imgSrc={user?.profileImageUrl} firstName={user?.firstName} onClick={off}>
              <SecondaryText
                onClick={e => {
                  e.stopPropagation();
                  router.push('/user/profile/edit', '/user/profile/edit');
                }}
              >
                {content.editProfile}
              </SecondaryText>
            </UserCard.User>
          </Link>
          <UserCard.Divider />
        </>
      )}
      {user?.isNightgraphUser ? (
        <Link href={`https://nightgraph.${host}`} aria-label="Nightgraph">
          <UserCard.Item color="nightgraph" fontWeight={900} onClick={off}>
            Nightgraph
          </UserCard.Item>
        </Link>
      ) : (
        <Link href="/business" as="/business" aria-label="Business">
          <UserCard.Item
            color="nightgraph"
            fontWeight={900}
            onClick={() => {
              GoogleTagManager.listEventTopbar();
              off();
            }}
          >
            {content.listAnEvent}
          </UserCard.Item>
        </Link>
      )}
      {isMobileOrTablet && (
        <UserCard.Item
          fontWeight={900}
          onClick={() => {
            toggleCities();
            off();
          }}
        >
          {content.discover}
        </UserCard.Item>
      )}
      {isMobileOrTablet && isAuthenticated && (
        <Link href="/user/saved" as="/user/saved" aria-label={content.saved}>
          <UserCard.Item fontWeight={900} onClick={off}>
            {content.saved}
          </UserCard.Item>
        </Link>
      )}
      {isMobileOrTablet && isAuthenticated && (
        <Link href="/user/bookings" as="/user/bookings" aria-label={content.bookings}>
          <UserCard.Item fontWeight={900} onClick={off}>
            {content.bookings}
          </UserCard.Item>
        </Link>
      )}
      <UserCard.Divider />
      <UserCard.Access onClick={isAuthenticated ? signout : toggleAuth}>
        {isAuthenticated ? content.logout : content.login}
      </UserCard.Access>
    </UserCard>
  );

  return (
    <NavBarContainer ref={setRef ? navbarRef : null} {...props}>
      <FullWidthContainer>
        <TopBar theme={theme}>
          {!isMobileOrTablet && (
            <Link href="/" as="/" aria-label="Homepage" display={['none', 'none', 'flex']}>
              <TopBar.Logo width={96} height={72} flex="unset" />
            </Link>
          )}
          {!isMobileOrTablet && shouldShowSearch ? (
            <TopBar.SearchBox
              placeholder={content.searchPlaceholder}
              onClick={toggleSearch}
              display={['none', 'none', 'flex']}
              flex={1}
            />
          ) : (
            <Flex flex={1} display={['none', 'none', 'flex']} />
          )}
          {!isMobileOrTablet && (
            <>
              <Link
                href={user?.isNightgraphUser ? `https://nightgraph.${host}` : '/business'}
                as={user?.isNightgraphUser ? '' : '/business'}
                aria-label={content.listAnEvent}
                display={['none', 'none', 'flex']}
              >
                <TopBar.TextItem
                  onClick={user?.isNightgraphUser ? null : GoogleTagManager.listEventTopbar}
                >
                  {user?.isNightgraphUser ? 'Nightgraph' : content.listAnEvent}
                </TopBar.TextItem>
              </Link>
              {shouldShowCities && (
                <TopBar.TextItem display={['none', 'none', 'flex']} onClick={toggleCities}>
                  {content.discover}
                </TopBar.TextItem>
              )}
              {isAuthenticated && (
                <Link
                  href="/user/saved"
                  as="/user/saved"
                  aria-label={content.saved}
                  display={['none', 'none', 'flex']}
                >
                  <TopBar.TextItem>{content.saved}</TopBar.TextItem>
                </Link>
              )}
              {isAuthenticated && (
                <Link
                  href="/user/bookings"
                  as="/user/bookings"
                  aria-label={content.bookings}
                  display={['none', 'none', 'flex']}
                >
                  <TopBar.TextItem>{content.bookings}</TopBar.TextItem>
                </Link>
              )}
            </>
          )}
          {!isMobileOrTablet && !isAuthenticated && !isLoading && (
            <TopBar.TextItem display={['none', 'none', 'flex']} onClick={toggleAuth}>
              {content.login}
            </TopBar.TextItem>
          )}
          {!isMobileOrTablet && (isAuthenticated || isLoading) && (
            <TopBar.AnchoredItem
              isOpen={value}
              onOutsideClick={off}
              trigger={
                isLoading ? (
                  <Loader size={65} />
                ) : (
                  <TopBar.User
                    onClick={on}
                    src={user?.profileImageUrl}
                    alt={user?.firstName}
                    disabled={value}
                  />
                )
              }
              left={[0, 0, 'unset']}
              right={['unset', 'unset', 0]}
            >
              {User}
            </TopBar.AnchoredItem>
          )}
          <TopBar.AnchoredItem
            isOpen={value}
            onOutsideClick={isMobileOrTablet ? off : null}
            trigger={<TopBar.More onClick={on} disabled={value} />}
            display={['block', 'block', 'none']}
            anchorX="left"
          >
            {User}
          </TopBar.AnchoredItem>
          <Link href="/" as="/" aria-label="Homepage" display={['flex', 'flex', 'none']} flex={1}>
            <TopBar.Logo width={80} height={60} />
          </Link>
          {shouldShowSearch ? (
            <TopBar.SearchButton display={['flex', 'flex', 'none']} onClick={toggleSearch} />
          ) : (
            <Flex width={42} />
          )}
        </TopBar>
      </FullWidthContainer>
    </NavBarContainer>
  );
};

export default Navbar;

Navbar.propTypes = {
  // eslint-disable-next-line react/require-default-props
  theme: PropTypes.string,
  setRef: PropTypes.bool,
};

Navbar.defaultProps = {
  setRef: false,
};
